<template>
  <div class="layout-padding">
    <PageTitle
      v-if="collection"
      class="my-8"
      :title="collection?.title"
      :childOne="content.collections"
      type="withBg"
    />

    <DetailsSkeleton v-if="loading" />
    <div v-else class="grid grid-cols-2 gap-x-10 md:row-start-2 mt-12">
      <div class="col-span-2 md:col-span-1 order-last md:order-first">
        <div class="lora-bold text-size-16 mb-4">
          {{ content.story }}
        </div>
        <div class="about-content">
          {{ collection?.story }}
        </div>
      </div>
      <div
        class="col-span-2 md:col-span-1 order-first md:order-last mb-12 md:mb-0"
      >
        <div class="relative">
          <div
            :style="`background: url(${collection?.image})`"
            class="about-slide-image"
          />
        </div>
      </div>
    </div>

    <div v-if="loading" class="lora-bold mt-16 mb-8 text-size-20">
      {{ content.products }}
    </div>
    <div v-else class="lora-bold mt-16 mb-8 text-size-20">
      {{ collection?.title }} {{ content.products_ }}
    </div>
    <div class="relative pb-24" v-if="collection?.products?.length > 0">
      <swiper
        :slides-per-view="4"
        :space-between="20"
        :breakpoints="breakpoints"
        :pagination="pagination"
        v-if="loading"
      >
        <swiper-slide v-for="index in 4" :key="index">
          <ProductCardSkeleton />
        </swiper-slide>
      </swiper>
      <swiper
        :slides-per-view="4"
        :space-between="20"
        :breakpoints="breakpoints"
        :pagination="pagination"
        :loop="true"
        v-else
      >
        <swiper-slide v-for="product in collection?.products" :key="product.id">
          <ProductCard :product="product" />
        </swiper-slide>
      </swiper>
      <div class="most-selling-pagination"></div>
    </div>
  </div>
</template>

<script>
import ProductCard from "/src/components/ui/Products/ProductCard.vue";
import DetailsSkeleton from "../../../components/ui/Skeletons/DetailsSkeleton.vue";
import ProductCardSkeleton from "../../../components/ui/Skeletons/ProductCardSkeleton.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination } from "swiper";

import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Navigation, Pagination]);

export default {
  components: {
    ProductCard,
    DetailsSkeleton,
    ProductCardSkeleton,
    Swiper,
    SwiperSlide,
  },
  props: ["id"],
  inject: ["content"],
  data() {
    return {
      loading: true,
      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        567: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        992: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1500: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1800: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
      pagination: {
        el: ".most-selling-pagination",
        type: "bullets",
        clickable: true,
        renderBullet: function () {
          return `<span class="dot swiper-pagination-bullet" style="background: #F26C57 !important; margin-inline-start:10px;width:10px;height:2px;margin-top:30px" ></span>`;
        },
      },
    };
  },
  async mounted() {
    await this.$store.dispatch("collections/collection", this.id);
    this.loading = false;
  },
  watch: {
    async id() {
      this.loading = true;
      await this.$store.dispatch("collections/collection", this.id);
      this.loading = false;
    },
  },
  computed: {
    collection() {
      return this.$store.getters["collections/collection"];
    },
  },
};
</script>